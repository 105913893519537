<!-- eslint-disable vue/camelcase -->
<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="REQUEST_DETAILS"
      tooltip-title="REQUEST_DETAILS"
      :display-breadcrumb="showBreadcrumb"
    ></TitleWrapper>

    <div v-if="!isLoading" class="w-full">
      <UserDetailedView :user="userCandidate" />
      <div v-if="requestLogs.length" class="mt-7.5 px-5">
        <div class="font-bold font-roboto text-xl text-label-text">Logs</div>
        <TableWrapper>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(log, index) in requestLogs" :key="index">
              <TD>
                {{ requestMessage(log) }}
              </TD>
              <TD>
                <span v-if="log.inserted_at" class="text-primary-green cursor-pointer">
                  {{ $filters.getTimeOnly(log.inserted_at) }}
                </span>
              </TD>
              <TD>
                <span v-if="log.status === 'request_closed' || log.status === 'request_denied'">
                  {{ log.message }}
                </span>
              </TD>
              <TD>
                {{ log.inserted_by.full_name }}
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
    </div>
    <div v-else>
      <Loader :content="true" />
    </div>
    <Pagination
      v-if="logsCounts > 10"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(logsCounts, filteredRecordLimit)"
      @filterRecord="getDetails"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import UserDetailedView from '@src/components/userDetailedView.vue'
import { mapActions } from 'vuex'
import { pickupRequestStyle } from '@src/utils/settings/tenant-user.util.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { mapState } from 'vuex'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import layoutConstants from '@src/constants/layout-constant.js'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import GeneralMixins from '@src/mixins/general-mixins.js'
import Loader from '@components/BaseComponent/Loader.vue'
import { formatDate } from '@utils/moment.util'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TitleWrapper,
    Pagination,
    UserDetailedView,
    Loader,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixins, scrollMixin],
  data() {
    return {
      TENANT_ROLES,
      GENERAL_CONSTANTS,
      DATE_TIME_CONSTANT,
      filteredRecordLimit: 10,
      requestDetails: {},
      userCandidate: null,
      tableHeaders: ['Status', 'Performed At', 'Reason', 'Performed By'],
      isLoading: false,
      requestLogs: [],
      showPagination: false,
      logsCounts: null,
    }
  },
  computed: {
    showBreadcrumb() {
      return this.activeRole === TENANT_ROLES.SUPER_ADMIN
    },
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
    }),
    requestMessage() {
      return (log) => {
        return this.requestedStatus(log)
      }
    },
  },

  page: {
    title: 'Pickup Request Detail',
  },
  mounted() {
    this.setRightBar()
    this.getDetails()
    if (this.activeRole === TENANT_ROLES.SECTION_TEACHER || this.activeRole === TENANT_ROLES.STAFF)
      this.setLeftBar()
  },
  methods: {
    getUserInfo(data) {
      let queryParam = paginationRangeHandler(data?.range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
      let query = {
        id: data?.id,
        payload: queryParam,
      }
      this.isLoading = true
      this.getUserDetails(query)
        .then((res) => {
          this.userCandidate = res.data
          this.userCandidate.requestStatus = this.requestDetails?.status
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setLeftBar() {
      this.setLeftbarContent({
        back: '',
        customeHeader: 'left-bar-class-list',
        SHOW_SECTION_DROPDOWN_TO: layoutConstants.showSectionDropdownTo,
        SHOW_CLASS_DROPDOWN_TO: layoutConstants.showClassDropdownTo,
        routes: [
          {
            id: '0',
            role: [TENANT_ROLES.SECTION_TEACHER, TENANT_ROLES.STAFF],
            name: 'LB_AR',
            route: { name: 'support-user-active-request' },
          },

          {
            id: '2',
            role: [TENANT_ROLES.SECTION_TEACHER, TENANT_ROLES.STAFF],
            name: 'LB_ALL_R',
            route: { name: 'support-user-all-requests' },
          },
        ],
      })
    },
    pickupRequestStyle,
    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'REQUEST_DETAILS',
        },
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('pickup', ['getRequestDetails']),
    ...mapActions('layout', ['setRightbarContent', 'setLeftbarContent']),
    ...mapActions('users', ['getUserDetails']),
    getDetails(range) {
      this.isLoading = true
      let id = this.$route.params?.id

      let payload = {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      this.getRequestDetails({ id: id, payload: payload }).then((res) => {
        this.requestDetails = res.pickup_request
        this.isLoading = false
        this.requestLogs = res.records
        this.requestLogs.forEach((log) => {
          fullName(log.inserted_by)
        })
        this.logsCounts = res.meta.total_records
        this.getUserInfo(this.requestDetails.student)
      })
    },
    requestedStatus(log) {
      let message
      switch (log) {
        case 'request_expired':
          message = 'Expired'
          break
        case 'request_sent':
          message = 'Request has Sent'
          break
        case 'request_closed':
          message = 'Closed'
          break
        case 'rerequested':
          message = 'Request Resend'
          break
        case 'request_denied':
          message = 'Rejected'
          break
        case 'student_picked':
          message = 'Student Pickup Up'
          break
        default:
          message = log.message
          break
      }
      return message
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
